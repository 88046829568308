// import styled from "styled-components"
import {Content, Container} from "./styling";

const About = (props) =>{
    document.title="About Us"

    return <Container>
        <Content>
            <h2>HELPFUL, ENTHUSIASTIC, PATIENT</h2>

            <p>
                We understand shopping for a special occassion dress can be overwhelming, which is why we always take the time to create a relationship with our clients by expressing patience, honesty, and enthusiasm. We are here to help you find your perfect dress and make you enjoy your experience with us. You won’t find any “overly pushy” sale professionals here, so relax and enjoy the search for your dream dress.
            </p>

            <h2>ON-SITE SEAMSTRESS</h2>

            <p>
                With over 30 years of experience, our professional and creative seamstress, Kim, will tailor and and fit your dress perfectly to your body. Kim takes pride in her work and always demonstrates accuracy and precision when tailoring. Is there something you’d like to change about your dress? Maybe add sleeves or lower the back? Kim has a creative eye for making one of a kind additions and changes! Just ask and you shall receive.
            </p>

            <h2>
                AN APPOINTMENT IS REQUIRED FOR ALL ALTERATIONS! PLEASE CALL (732)671-3800 TO SCHEDULE.
            <br/>
            </h2>
            <p>
            Please call for outside alterations. Availability will change upon season.      
            <br/>
            </p>

            <h2>
                STORE HOURS:
            </h2>
            <p>
                Sunday: Closed
                <br/>
                Monday: By appointment only
                <br/>
                Tuesday: Closed
                <br/>
                Wednesday: By appointment only
                <br/>
                Thursday: By appointment only
                <br/>
                Friday: By appointment only
                <br/>
                Saturday: By appointment only
                <br/>    
            </p>
            <h2>LOCATION</h2>
            <p>749 Highway 35 Middletown, NJ 07748</p>
            
            <h2>EMAIL</h2>
            <p>bellesbridal@hotmail.com</p>
        </Content>
    </Container>

};

export default About;